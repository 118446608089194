import { helpers } from 'vuelidate/lib/validators';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const isNameValid = helpers.regex('isNameValid', /^[a-z\xC0-\uFFFF\- ]*$/i);
export const isAddressValid = helpers.regex('isAddressValid', /^[a-zåäöé0-9\- ]*$/i);
export const isPhoneValid = helpers.regex('isPhoneValid', /^\+?[0-9]*$/i);
export const isPersonnrValid = helpers.regex('isPersonnrValid', /^[0-9]{6,8}\-{0,1}[0-9]{4}$/i);
export const isDOBValid = (value) => {
    const date = dayjs(value, 'YYYYMMDD', true);
    if (!date.isValid()) {
        return false;
    }
    return date.isAfter('1900-01-01') && date.isBefore('2100-01-01');
};