<template>
  <div>
    
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-layout row wrap align-center justify-center>
        <v-flex>

          <v-row v-if="!done">
            <v-col align-self="center">
              <v-sheet class="pa-2 ma-2">
                <InquiryCompanyFormStep1 @next="next_step" v-if="step === 0" />

                <InquiryCompanyFormStep2 @back="back" @next="next_step" v-if="step === 1" />

                <InquiryCompanyFormStep3 @back="back" @next="next_step" v-if="step === 2" />
            
              </v-sheet>
            </v-col>
          </v-row>

          <v-card style="min-width: 400px; max-width: 640px;" class="mx-auto mt-16 mb-32" v-else-if="error">
            <div class="col-12 text-center">
              <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
            </div>

            <div class="mx-auto pb-8">
              <p style="white-space: pre; text-align: center;"><span class="text-center" style="font-weight: bold;">{{ $t('PUBLIC.INQUIRY.CREATE_FAILURE') }}</span></p>

              <p style="text-align: center;" >{{ $t('PUBLIC.INQUIRY.CREATE_FAILURE_INFO') }}</p>
            </div>
        
          </v-card>


          <v-card style="min-width: 400px; max-width: 640px;" class="mx-auto mt-16 mb-32" v-else-if="inquiry.id">
            <div class="col-12 text-center">
              <i class="fa fa-check-circle" style="font-size: 42px; color: #8dda8d;"></i>
            </div>

            <div class="mx-auto pb-8">
              <p style="white-space: pre; text-align: center;"><span class="text-center" style="font-weight: bold;">{{ $t('PUBLIC.INQUIRY.CREATED_SUCCESSFULLY') }}</span></p>

              <p style="text-align: center;" >{{ $t('PUBLIC.INQUIRY.CREATED_SUCCESSFULLY_INFO') }}</p>
            </div>
        
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>

:deep .v-application--wrap {
  background: white !important;
}

:deep body {
  background: white !important;
}

</style>

<script>
import messageParent from '@/core/services/messageParent';
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


import InquiryCompanyFormStep1 from '@/view/pages/public/InquiryCompanyFormStep1.vue';
import InquiryCompanyFormStep2 from '@/view/pages/public/InquiryCompanyFormStep2.vue';
import InquiryCompanyFormStep3 from '@/view/pages/public/InquiryCompanyFormStep3.vue';

export default {
  name: 'InquiryCompanyForm',
  mixins: [ toasts ],

  components: {
    InquiryCompanyFormStep1,
    InquiryCompanyFormStep2,
    InquiryCompanyFormStep3
  },

  data() {
    return {
      error: false,
      done: false,
      inquiry: {
        id: 1
      },

      form: {},
      step: 0
    };
  },
  mounted() {
    setInterval(function(){
      messageParent(JSON.stringify({ height: document.body.scrollHeight }));
    }, 300);
  },
  watch: {
    
  },
  methods: {

    back() {
      this.step = this.step - 1;
    },
    
    next_step(form, step) {

      for (let key in form) {
        this.form[key] = form[key];
      }

      this.form = { ...this.form };

      if (this.step === 2) {
        this.post_inquiry();
      }
      else {
        this.step = this.step + 1;
      }

    },

    async post_inquiry() {
      try {
        const res = await axios.post(`/customer/inquiry`, this.form);

        this.done = true;

        if (res.status === 201) {
          /// show success message

          this.inquiry = res.data;

          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.error = true;
    }

  }
};
</script>
