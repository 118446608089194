<template>
  <div>
    <h4>{{ $t('PUBLIC.INQUIRY.ORG_INFO') }}</h4>

    <v-row class="mt-4">
      <v-col lg="6">
        
        <memlist-text-input
          id="name"
          name="name"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.INQUIRY.ORG_NAME')"
          v-model="form.name"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.INQUIRY.ORG_NAME')"
          :required="true"
          :invalid_text="$t('PUBLIC.INQUIRY.INPUT_ORG_NAME')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>
    </v-row>

    <v-row>
      <v-col lg="6">
        

          <memlist-text-input
            id="c_name"
            name="c_name"
            validation_type="TEXT"
            layout="vertical"
            :title="$t('PUBLIC.INQUIRY.C_NAME')"

            v-model="form.c_name"
            :validate="force_validate"
            :placeholder="$t('PUBLIC.INQUIRY.C_NAME')"
            :required="true"
            :invalid_text="$t('PUBLIC.INQUIRY.INPUT_C_NAME')"
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="6">

        <memlist-text-input
          id="admin_email"
          name="admin_email"
          :title="$t('PUBLIC.INQUIRY.ADMIN_EMAIL')"
          layout="vertical"
          validation_type="EMAIL"

          v-model="form.admin_email"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.INQUIRY.ADMIN_EMAIL')"
          :required="true"
          :invalid_text="$t('PUBLIC.INQUIRY.INPUT_ADMIN_EMAIL')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

        
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="6">

        <memlist-text-input
          id="admin_phone"
          name="admin_phone"
          :title="$t('PUBLIC.INQUIRY.ADMIN_PHONE')"
          layout="vertical"
          validation_type="TEXT"

          v-model="form.admin_phone"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.INQUIRY.ADMIN_PHONE')"
          :required="false"
          :invalid_text="$t('PUBLIC.INQUIRY.INPUT_ADMIN_PHONE')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>
    </v-row>

    <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.INQUIRY.MISSING_FIELDS') }}</p>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
      
      <b-button
        class="mt-4"
        variant="primary"
        :type="type"
        :disabled="disabled || spinning"
        @click.prevent="on_click"
      >
        <b-spinner v-if="spinning" small type="grow"></b-spinner>
        {{ $t('COMMON.CONTINUE') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { isPersonnrValid } from '@/core/services/validators';
import { requiredUnless } from 'vuelidate/lib/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { email, minLength, maxLength } from '@vuelidate/validators';

export default {
  name: 'InquiryCompanyFormStep1',
  mixins: [ toasts ],

  data() {
    return {
      form: {},
      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {
    
  },
  watch: {
    
  },
  methods: {
    update_form(field_name, value) {
      this.form[field_name] = value;

      this.form = { ...this.form };
    },
    validated(field_name, value, valid) {
      this.form[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_click() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        window.scrollTo(0,0);

        if (this.is_form_valid) {
          this.spinning = false;

          this.$emit('next', this.form, 0);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

  }
};
</script>
